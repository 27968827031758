import Vue from "vue";
import {
  ValidationProvider,
  extend,
  ValidationObserver,
  configure,
} from "vee-validate";
import {
  required,
  image,
  size,
  max,
  min,
  email,
  integer,
} from "vee-validate/dist/rules";

const config = {
  mode: "lazy",
};
extend("required", {
  ...required,
  message: "Это обязательное поле",
});
extend("integer", {
  ...integer,
  message: "Укажите целочисленное значение",
});
extend("email", {
  ...email,
  message: "Укажите адрес электронной почты",
});
extend("image", {
  ...image,
  message() {
    return `Загруженный файл не является изображением`;
  },
});
extend("size", {
  ...size,
  message(fname, param) {
    return `Размер файла ${param._value_.name} не должен превышать ${
      param.size * 0.001
    } МБ`;
  },
});
extend("max", {
  ...max,
  message(fname, param) {
    return `Максимальное количество символов: ${param.length}`;
  },
});

extend("min", {
  ...min,
  message(fname, param) {
    return `Минимальное количество символов: ${param.length}`;
  },
});

// Sets the options.
configure(config);
// Register it globally
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
